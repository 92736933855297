import React, {Component} from 'react'
import Lottie from 'react-lottie';
import animationData from './data.json'

class LottieControl extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <div className="ani-wrap">
      <Lottie options={defaultOptions}/>
    </div>
  }
}

export default LottieControl
